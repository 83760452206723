import { Dialog, DialogTitle } from '@material-ui/core'
import React from 'react'

import ErrorDisplay from './ErrorDisplay'

export type ErrorDialogProps = {
  error: Error | undefined
  onClose: () => void
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({ error, onClose }) => {
  return (
    <Dialog open={!!error}>
      <DialogTitle>Authentication Error:</DialogTitle>
      <ErrorDisplay error={error} onClose={onClose} />
    </Dialog>
  )
}
