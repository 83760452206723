// export * from './types'
import { GetSettingsQueryResult, useGetSettingsQuery } from '../graphql'
import { makeQueryWithReducer } from '../utils'
import { useWithPerson } from '../utils/useWithPerson'

export const useGetSettingsQueryWithReducer = makeQueryWithReducer(
  useGetSettingsQuery,
  (result): NonNullable<GetSettingsQueryResult['data']> => result.data || {},
  useWithPerson,
)
