import { CookieSetOptions } from 'universal-cookie'

export * from 'react-cookie'

export const setCookieOpts: CookieSetOptions = {
  path: '/',
  maxAge: 60 * 60 * 24, // 24 hours
  secure: process.env.NODE_ENV !== 'development',
  sameSite: 'lax',
}

/* Cookie names */
export const LOGIN_REDIRECT_COOKIE_NAME = 'aletheia_login_redirect'
