import { Draft, produce } from 'immer'
import difference from 'lodash/difference'
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'

import { ErrorDialog, ErrorDialogProps } from './ErrorDialog'

const AuthContext = createContext<AuthValue>({
  user: undefined,
  refreshUser: () => undefined,
  loading: false,
})
AuthContext.displayName = 'AuthContext'
const { Provider } = AuthContext

export type TAuth0User = {
  given_name?: string | null
  family_name?: string | null
  nickname?: string | null
  name?: string | null
  picture?: string | null
  updated_at: string
  email: string
  email_verified: boolean
  sub: string
}

export type AuthProps = {
  readonly user: TAuth0User | undefined
  readonly loading: boolean
}

export type AuthMethods = {
  refreshUser: () => void
}

export type AuthValue = AuthProps & AuthMethods

export type AuthState = AuthProps & {
  readonly error?: Error
}

export const initialState: AuthState = {
  user: undefined,
  // Initial state is set to loading as the first thing we will do on mount is load the user
  // This avoids issues on re-hydration when using Static Rendering
  loading: true,
}

/** Update the `user` property of an `AuthState` object */
export function setUser(state: AuthState, user: TAuth0User): AuthState {
  return produce(state, (draft: Draft<AuthState>) => {
    draft.user = user
  })
}

/** Update the `Loading` property of an `AuthState` object */
export function setLoading(state: AuthState, loading: boolean): AuthState {
  return produce(state, (draft: Draft<AuthState>) => {
    draft.loading = loading
  })
}

/** Update the `Error` property of an `AuthState` object */
export function setError(
  state: AuthState,
  error: Error | undefined,
): AuthState {
  return produce(state, (draft: Draft<AuthState>) => {
    draft.error = error
  })
}

type AuthProviderProps = {
  requiredRoles?: string[]
  errorDialog?: React.FC<ErrorDialogProps>
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  requiredRoles,
  errorDialog = ErrorDialog,
}) => {
  const [state, setState] = useState<AuthState>(initialState)

  /**
   * Get the Auth0 session object, check scopes for the required API access
   * permissions, and push the user object into component state
   */
  const initSession = async (): Promise<void> => {
    try {
      await handleSetLoading(true)
      const res = await fetch('/api/auth/session')
      if (res.ok) {
        const session = await res.json()
        // check we have an access scope on the token
        if (requiredRoles?.length) {
          const diff = difference(requiredRoles, session.has_roles)
          if (diff.length) {
            /** Immediately throw an error if the necessary scopes aren't present */
            throw new Error(
              `Could not retrieve access token with correct permissions.`,
            )
          }
        }
        // add the user to state
        const user: TAuth0User = session.user
        setState((state) => setUser(state, user))
      }
    } catch (err: any) {
      setState((state) => setError(state, err))
    } finally {
      await handleSetLoading(false)
    }
  }

  /**
   * Get an updated user object. Useful if you've recently updated the user's
   * information
   *
   * The `initSession` function uses `/api/auth/session`, but this doesn't
   * refresh the user's data on the ID token. Instead, it's necessary to use
   * `/api/auth/me`, which will refresh the user object
   */
  async function getUser() {
    await handleSetLoading(true)
    const res = await fetch('/api/auth/me')
    if (res.ok) {
      const user: TAuth0User = await res.json()
      setState((state) => setUser(state, user))
    }
  }

  const handleSetLoading = (loading: boolean) => {
    setState((state) => setLoading(state, loading))
  }

  /**
   * Refresh the Auth0 user object on the session (e.g. if it's been updated on
   * Auth0 because the user has changed their name)
   */
  const refreshUser = useCallback(async () => {
    await getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Initialize session on component mount */
  useEffect(() => {
    initSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Log errors out when they get set */
  useEffect(() => {
    if (state.error) {
      console.error(state.error.message)
    }
  }, [state.error])

  const { error, ...rest } = state
  const value = { ...rest, refreshUser }
  const Dialog = errorDialog
  const onClose = () => setState((state) => setError(state, undefined))
  return (
    <Provider value={value}>
      <Dialog error={error} onClose={onClose} />
      {children}
    </Provider>
  )
}

export const useAuth = (): AuthValue => useContext(AuthContext)
