/**
 * Utilities for parsing database types into usefully-typed JavaScript
 */
import { z } from 'zod'

/** parse a BIGINT column into a JS number */
export const bigint = z
  .string()
  .refine((val) => !isNaN(+val))
  .transform((val) => +val)

/** parse a NUMERIC column into a JS number */
export const numeric = bigint

/** Parse an int8range (range type for BIGINT) into [number, number]. Upper bound is inclusive */
export const int8range = z
  .object({
    start: z
      .object({
        value: bigint,
        inclusive: z.boolean(),
      })
      .transform(({ value, inclusive }) => (inclusive ? value : value + 1)),
    end: z
      .object({
        value: bigint,
        inclusive: z.boolean(),
      })
      .transform(({ value, inclusive }) => (inclusive ? value : value - 1)),
  })
  .transform(({ start, end }): [number, number] => [start, end])
